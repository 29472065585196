<template lang="pug">
  v-bottom-navigation.custom-bottom-nav__nav(app scroll-target="#scroll-area-1" hide-on-scroll="" absolute="" horizontal="")
    v-btn(v-for="item in itemsBottomNavContributor" :key="item.id" :to="item.to")
      .custom-arc
      img(:src="item.icon" class="icon-normal" width="35px" height="auto")
      img(:src="item.iconActive" class="icon-active")
</template>
<script>
  export default {
    name: 'BottomNav',
    data () {
      return {
        itemsBottomNavContributor: [
          {
            to: '/contributor/#',
            name: '',
            icon: require('@/assets/images/icon_home.png'),
            iconActive: require('@/assets/images/icon_home_active.png'),
            authRequired: false,
          },
          {
            to: '/contributor/videos',
            name: '',
            icon: require('@/assets/images/icon_video.png'),
            iconActive: require('@/assets/images/icon_video_active.png'),
            authRequired: true,
          },
          {
            to: '/contributor/camera',
            name: '',
            icon: require('@/assets/images/icon_camera.png'),
            iconActive: require('@/assets/images/icon_camera.png'),
            authRequired: true,
          },
          {
            to: '/contributor/notifications',
            name: '',
            icon: require('@/assets/images/icon4.png'),
            iconActive: require('@/assets/images/icon4_active.png'),
            authRequired: true,
          },
          {
            to: '/contributor/profile',
            name: '',
            icon: require('@/assets/images/icon5.png'),
            iconActive: require('@/assets/images/icon5_active.png'),
            authRequired: true,
          },
        ],
      }
    },
    methods: {
      openDialog (dialog) {
        this.$store.dispatch('dialogs/openDialog', dialog).then(function () {

        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .v-btn--active{
  background: #a3a3a3 !important;
  }
/*.custom-bottom-nav{*/
    /*margin: 0 !important;*/
  /*}*/
  /*.custom-bottom-nav__nav{*/
    /*position: fixed !important;*/
  /*}*/

</style>
