import { render, staticRenderFns } from "./BottomNav.vue?vue&type=template&id=45f99c3c&scoped=true&lang=pug&"
import script from "./BottomNav.vue?vue&type=script&lang=js&"
export * from "./BottomNav.vue?vue&type=script&lang=js&"
import style0 from "./BottomNav.vue?vue&type=style&index=0&id=45f99c3c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f99c3c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBottomNavigation,VBtn})
